$primary: #1a2334;
$secondary: #6f7f8c;
$success: #151c2a;
$danger: #cc330d;
$info: #5c8f94;
$warning: #ffdc00;
$light: #eceeec;
$dark: #1e2b37;

$body-bg: #fff;
$body-color: #1a2334;

$font-weight-normal: 400;
$font-family-base: "Inter", sans-serif;
$font-family-sans-serif: "Inter", sans-serif;

$container-max-widths: (
  md: 720px,
  lg: 960px,
  xl: 1440px,
);

.error-message {
  font-size: 14px;
  font-weight: 500;
}
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
