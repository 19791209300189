@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;700&display=swap");

.body {
  font-family: "Inter", sans-serif;
  font-size: 12px;
}

.message-toast {
  z-index: 1;
}
